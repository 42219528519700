<template>
  <div class="space-x-4 flex items-center">
    <div
      class="flex items-center justify-center text-[#e05206] bg-[#FFCEB3] rounded-xl p-3 w-10 h-10"
    >
      <ClientOnly>
        <fa-icon :icon="icon" />
      </ClientOnly>
    </div>

    <p class="text-gray-800">
      {{ content }}
    </p>
  </div>
</template>
<script setup lang="ts">
export type FeatureModel = {
  icon: string
  content: string
}
export type FeatureProps = {
  model: FeatureModel
}
const props = defineProps<FeatureProps>()
const { icon, content } = toRefs(props.model)
</script>
