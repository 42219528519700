<template>
  <form
    class="bg-[#757575] flex items-center py-8 px-4"
    @submit.prevent="handleSubmit"
  >
    <div class="mt-4 max-w-sm w-full mx-auto">
      <div
        v-if="status === 'ERROR'"
        class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
        role="alert"
      >
        <span class="font-medium">Something went wrong!</span> Your query could
        not be sent. Please reach out via Etsy.
      </div>
      <div
        v-if="status === 'SUCCESS'"
        class="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
        role="alert"
      >
        <span class="font-medium">Message sent!</span> Thanks for your query. We
        will respond as soon as we can.
      </div>
      <UiInput v-model="model.name" name="name" label="Name" required />
      <UiInput
        v-model="model.email"
        name="email"
        label="Email Address"
        type="email"
        required
      />
      <UiInput
        v-model="model.contact"
        name="contact"
        label="Contact Number"
        type="tel"
        required
      />
      <UiInput
        v-model="model.message"
        name="message"
        label="Message"
        required
      />
      <div class="mt-6">
        <button
          class="rounded-md relative inline-flex group items-center justify-center px-6 pb-1.5 pt-2 cursor-pointer font-semibold border-b-4 border-l-2 active:shadow-none shadow-lg text-white overflow-clip active:border-[#FF5900] bg-gradient-to-tr from-[#e05206] to-[#FF5900] border-[#e05206] disabled:bg-grey-300"
          :disabled="!buttonEnabled"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
</template>
<script setup lang="ts">
const model = reactive({
  name: '',
  email: '',
  contact: '',
  message: '',
})
const buttonEnabled = computed(
  () => model.name && model.email && model.contact && model.message
)

const status = ref()
const handleSubmit = async () => {
  try {
    status.value = undefined
    await $fetch('https://usebasin.com/f/4fb5da44bd82', {
      method: 'POST',
      body: model,
    })
    status.value = 'SUCCESS'
    model.name = ''
    model.email = ''
    model.contact = ''
    model.message = ''
  } catch (err) {
    console.log(err)
    status.value = 'ERROR'
  }
}
</script>
