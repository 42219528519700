<template>
  <div class="lg:px-6 pb-4 mx-auto">
    <carousel :autoplay="5000" wrap-around pause-autoplay-on-hover>
      <template #default>
        <slide v-for="(slide, index) in slides" :key="index">
          <div class="h-80 w-full relative overflow-hidden">
            <NuxtImg
              :src="slide.img"
              :alt="slide.alt"
              class="object-cover lg:absolute w-full h-full"
              width="1232"
              height="384"
              :defer="index > 0"
              sizes="100vw lg:980px xl:1280px"
            />
            <div
              class="z-50 absolute top-0 w-full lg:w-1/2 h-full flex items-center text-left py-10 px-12"
            >
              <div class="space-y-4 bg-black bg-opacity-30 p-4 text-white">
                <h1 class="text-white text-3xl title-font font-medium mb-1">
                  {{ slide.title }}
                </h1>
                <NuxtLink
                  v-if="slide.link"
                  :to="slide.link"
                  tabindex="-1"
                  class="font-semibold transition-colors duration-200"
                >
                  More Information
                  <svg
                    class="inline-block w-3 ml-2"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path
                      d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z"
                    ></path>
                  </svg>
                </NuxtLink>
              </div>
            </div>
          </div>
        </slide>
      </template>
      <template #addons>
        <navigation />
      </template>
    </carousel>
  </div>
  <div>
    <div class="px-6 py-4 mx-auto">
      <h2 id="journals" class="text-2xl font-semibold capitalize lg:text-3xl">
        Our Journals
      </h2>
      <UiDivider class="mt-2" />
    </div>
    <UiSection
      id="footy-fantastic-match-diary"
      title="Footy Fantastic: Match Diary and Training Journal"
      flare="Etsy Best Seller"
      :img="{
        src: '/img/main_md.webp',
        width: 897,
        height: 1024,
      }"
    >
      <template #default>
        <p class="my-3 text-sm text-gray-800 md:text-sm">
          Featuring over 140 pages to record all their soccer training and match
          activities, track skills, training sessions, match results and player
          wellbeing.
        </p>
        <div class="flex items-center">
          <UiButton
            to="https://www.etsy.com/uk/listing/1376952155/footy-fantastic-wiro-bound-matchday"
            class="mr-6"
          >
            Buy
          </UiButton>
          <NuxtLink
            to="/books/footy-fantastic-wiro-bound"
            class="mr-6 inline-flex items-center font-semibold text-[#B54103]"
          >
            More About This Book
          </NuxtLink>
        </div>
      </template>
    </UiSection>
    <UiSection
      id="rugby-review"
      title="Rugby Review: Matchday, Training & Activity Book"
      :img="{
        src: '/img/main_rr.webp',
        width: 768,
        height: 1024,
      }"
      rtl
    >
      <template #default>
        <p class="my-3 text-sm text-gray-800 md:text-sm">
          Featuring over 140 pages to record all their soccer training and match
          activities, track skills, training sessions, match results and player
          wellbeing.
        </p>
        <div class="flex items-center">
          <UiButton
            to="https://www.etsy.com/uk/listing/1479389701/rugby-review-rugby-match-diary-training"
            class="mr-6"
          >
            Buy
          </UiButton>
          <NuxtLink
            to="/books/rugby-review"
            class="mr-6 inline-flex items-center font-semibold text-[#B54103]"
          >
            More About This Book
          </NuxtLink>
        </div>
      </template>
    </UiSection>
    <UiSection
      id="footy-fantastic"
      title="Footy Fantastic Football Fun, Skills & Activities for Kids"
      :img="{
        src: '/img/main_ff.webp',
        width: 739,
        height: 1024,
      }"
    >
      <template #default>
        <p class="my-3 text-sm text-gray-800 md:text-sm">
          A football themed activity book, Footy Fantastic has been especially
          designed to include unique, fun colouring, puzzles and build projects
          for boys and girls aged 6 to 12, who love football!
        </p>
        <div class="flex items-center">
          <UiButton
            to="https://www.etsy.com/uk/listing/1111983532/footy-fantastic-football-fun-skills"
            class="mr-6"
          >
            Buy
          </UiButton>
          <NuxtLink
            to="/books/footy-fantastic"
            class="mr-6 inline-flex items-center font-semibold text-[#B54103]"
          >
            More About This Book
          </NuxtLink>
        </div>
      </template>
    </UiSection>
    <UiSection
      id="footy-fantastic-2"
      title="Footy Fantastic 2 - MORE Football Fun for kids!"
      :img="{
        src: '/img/main_ff2.webp',
        width: 744,
        height: 1024,
      }"
      rtl
    >
      <template #default>
        <p class="my-3 text-sm text-gray-800 md:text-sm">
          A follow up to our popular first football themed activity book, Footy
          Fantastic 2 has been especially designed to include unique, fun
          colouring, puzzles and build projects for boys and girls aged 6 to 12,
          who love football!
        </p>
        <div class="flex items-center">
          <UiButton
            to="https://www.etsy.com/uk/listing/1125930333/footy-fantastic-2-more-football-fun-for"
            class="mr-6"
          >
            Buy
          </UiButton>
          <NuxtLink
            to="/books/footy-fantastic-2"
            class="mr-6 inline-flex items-center font-semibold text-[#B54103]"
          >
            More About This Book
          </NuxtLink>
        </div>
      </template>
    </UiSection>
    <UiSection
      id="gymnastic-fantastic"
      title="Gymnastic Fantastic!"
      flare="Coming soon!"
      :img="{
        src: '/img/main_gf.webp',
        width: 897,
        height: 1024,
      }"
    >
      <template #default>
        <p class="my-3 text-sm text-gray-800 md:text-sm">
          Another brilliant training journal, better than ever and packed with
          useful tools to measure performance and progression, including the
          fantastic format of inspirational quotes and facts for budding young
          gymnasts!
        </p>
        <div class="flex items-center">
          <UiButton
            to="https://www.etsy.com/uk/listing/1589216627"
            class="mr-6"
          >
            Buy
          </UiButton>
          <NuxtLink
            to="/books/gymnastic-fantastic"
            class="mr-6 inline-flex items-center font-semibold text-[#B54103]"
          >
            More About This Book
          </NuxtLink>
        </div>
      </template>
    </UiSection>
  </div>
  <section class="bg-white mt-3">
    <div class="px-6 py-10 mx-auto">
      <h2 id="customise" class="text-2xl font-semibold capitalize lg:text-3xl">
        Customise for your club
      </h2>
      <UiDivider class="mt-2" />

      <div class="mt-8 xl:mt-12 lg:flex lg:items-center">
        <div class="lg:w-1/2">
          <p class="mb-5 lg:mb-16 text-gray-800 text-justify">
            Any of our journals can be personalised for your club. The journals
            are an excellent tool for your players or athletes to reflect on the
            what and why of their training, and also provides a wonderful
            insight for you as a coach into how well your sessions are being
            received. You can gain as much benefit from the journals as the
            journal keeper.
          </p>
          <div class="grid grid-cols-1 gap-8 xl:gap-16 md:grid-cols-2">
            <Feature
              v-for="(feat, index) in custom"
              :key="index"
              :model="feat"
            />
          </div>
          <p class="mt-8 lg:mt-16 text-gray-800 font-medium text-justify">
            Please contact us for more info!
          </p>
        </div>

        <div class="hidden lg:flex lg:w-1/2 lg:justify-center">
          <NuxtImg
            class="w-[28rem] h-[28rem] flex-shrink-0 object-cover xl:w-[34rem] xl:h-[34rem]"
            src="/img/custom.webp"
            alt="A Whitley Bay football shirt with Footy Fantastic sponsor emblem"
            width="640"
            height="630"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </section>
  <section class="bg-white">
    <div class="px-6 py-10 mx-auto">
      <h2 id="reviews" class="text-2xl font-semibold capitalize lg:text-3xl">
        What our customers say
      </h2>
      <UiDivider class="mt-2" />
      <section
        class="grid grid-cols-1 gap-4 lg:gap-8 mt-8 xl:mt-12 lg:grid-cols-3"
      >
        <Review
          v-for="(review, index) in reviews"
          :key="index"
          :model="review"
        />
      </section>
      <div class="flex justify-around items center mt-8">
        <div class="w-24">
          <NuxtImg
            src="/img/etsy.webp"
            alt="The Etsy logo"
            width="1080"
            height="512"
            loading="lazy"
          />
        </div>
        <div class="w-24">
          <NuxtImg
            src="/img/noths.webp"
            alt="Not on the High Street logo"
            width="300"
            height="147"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </section>
  <section class="bg-white">
    <div class="mx-auto py-10">
      <div class="px-6">
        <h2
          id="contact-us"
          class="text-2xl font-semibold capitalize lg:text-3xl"
        >
          Contact us
        </h2>
        <UiDivider class="mt-2" />
      </div>
      <div class="grid grid-cols-1 gap-0 mt-8 xl:mt-12 lg:grid-cols-2 lg:px-6">
        <div class="hidden lg:flex lg:justify-center">
          <NuxtImg
            class="object-cover"
            src="/img/logo.webp"
            alt="Foot Fantastic"
            width="640"
            height="480"
            loading="lazy"
          />
        </div>
        <Contact />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
const slides = ref([
  {
    title: 'Footy Fantastic: Match Diary and Training Journal',
    img: '/img/md_slide.webp',
    alt: 'Footy Fantastic: Match Diary and Training Journal book',
    highlighted: 'A guided activity book for football mad kids.',
    text: 'Featuring over 140 pages to record all their soccer training and match activities, track skills, training sessions, match results and player wellbeing.',
    link: '#footy-fantastic-match-diary',
    shop: 'https://www.etsy.com/uk/listing/1376952155/footy-fantastic-wiro-bound-matchday',
    width: 897,
    height: 1024,
  },
  {
    title: 'Footy Fantastic RUGBY REVIEW: Matchday, Training & Activity Book!',
    img: '/img/rr_slide.webp',
    alt: 'Footy Fantastic RUGBY REVIEW: Matchday, Training & Activity Book',
    highlighted: 'A guided activity book for rugby mad kids.',
    text: 'Featuring over 140 pages to record all their soccer training and match activities, track skills, training sessions, match results and player wellbeing.',
    link: '#rugby-review',
    shop: 'https://www.etsy.com/uk/listing/1479389701/rugby-review-rugby-match-diary-training',
    width: 768,
    height: 1024,
  },
  {
    title: 'Footy Fantastic Football Fun, Skills & Activities for Kids',
    img: '/img/ff_slide.webp',
    alt: 'Footy Fantastic Football Fun, Skills & Activities for Kids book',
    text: 'A football themed activity book, Footy Fantastic has been especially designed to include unique, fun colouring, puzzles and build projects for boys and girls aged 6 to 12, who love football!',
    link: '#footy-fantastic',
    shop: 'https://www.etsy.com/uk/listing/1111983532/footy-fantastic-football-fun-skills',
    width: 739,
    height: 1024,
  },
  {
    title: 'Footy Fantastic 2 - MORE Football Fun for kids!',
    img: '/img/ff2_slide.webp',
    alt: 'Footy Fantastic 2 - MORE Football Fun for kids book',
    text: 'A follow up to our popular first football themed activity book, Footy Fantastic 2 has been especially designed to include unique, fun colouring, puzzles and build projects for boys and girls aged 6 to 12, who love football!',
    link: '#footy-fantastic-2',
    shop: 'https://www.etsy.com/uk/listing/1125930333/footy-fantastic-2-more-football-fun-for',
    width: 744,
    height: 1024,
  },
  {
    title: 'Coming soon! Gymnastic Fantastic!',
    img: '/img/gf_slide.webp',
    alt: 'Gymnastic Fantastic book',
    text: 'Another brilliant training journal, better than ever and packed with useful tools to measure performance and progression, including the fantastic format of inspirational quotes and facts for budding young gymnasts!',
    width: 897,
    height: 1024,
  },
])
const reviews = ref([
  {
    stars: 5,
    title: 'Happy for hours',
    book: 'Footy Fantastic 2 - MORE Football Fun for kids!',
    bookLink: '#footy-fantastic-2',
    name: 'Alex D',
    date: 'Oct 23, 2022',
    content:
      'This book looks like it will keep my grandson happy for hours. I am sure he will love it, bought as a present.',
  },
  {
    stars: 5,
    title: 'Delighted',
    book: 'Footy Fantastic Football Fun, Skills & Activities for Kids',
    bookLink: '#footy-fantastic',
    name: 'Christine B',
    date: 'Sep 6, 2023',
    content:
      'These books are so good... Crammed full of things to do. Bought both designs for Gradnson. Such a good idea and perfect for a young football fan. Delighted.. Thanks again.',
  },
  {
    stars: 5,
    title: 'Amazing',
    book: 'Footy Fantastic: Match Diary and Training Journal',
    bookLink: '#footy-fantastic-match-diary',
    name: 'Verified Etsy Buyer',
    date: 'Oct 13, 2023',
    content:
      'My daughter loves her match diary, we have bought again for her friends birthdays! Amazing service.',
  },
])
const custom = ref([
  {
    icon: 'shield',
    content: 'Your club name and branding on the cover',
  },
  {
    icon: 'message',
    content: 'Internal personalised club welcome message',
  },
  {
    icon: 'note-sticky',
    content: 'Additional competition or training pages can be added',
  },
  {
    icon: 'tag',
    content: 'All this for no added cost (50 copy limit)',
  },
])
</script>
