<template>
  <div class="mt-2">
    <label class="text-white text-sm" :for="name">{{ label }}</label>
    <component
      :is="as"
      :id="name"
      :type="as === 'input' ? type ?? 'text' : undefined"
      :value="modelValue"
      class="block w-full text-sm px-4 py-2 text-[#121212] bg-white border border-[#f6f2f2] rounded-md focus:border-[#e05206] focus:ring-[#e05206] focus:ring-opacity-50 focus:outline-none focus:ring"
      @input="handleInput"
    />
  </div>
</template>
<script setup lang="ts">
export type InputProps = {
  modelValue?: string
  name: string
  label: string
  type?: string
  as?: 'input' | 'textarea'
}
export type InputEmits = {
  (e: 'update:modelValue', value: string | undefined): void
}

withDefaults(defineProps<InputProps>(), {
  as: 'input',
})

const emit = defineEmits<InputEmits>()
const handleInput = ({ currentTarget }: Event) =>
  emit('update:modelValue', (currentTarget as any).value)
</script>
